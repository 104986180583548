<template>
  <div class="join">
    <div class="baseBox">
      <div class="joinBox">
        <h1>{{ $t("112") }}</h1>
        <div class="form">
          <div class="flexB">
            <p>{{ $t("user-data-pw") }} <span>*</span></p>
            <div>
              <input type="password" v-model="password" />
              <span>{{ $t("115") }}</span>
            </div>
          </div>
          <div class="flexB">
            <p>{{ $t("user-data-pw-confirm") }}<span>*</span></p>
            <div>
              <input type="password" v-model="passwordConfirm" />
              <span class="errror" v-if="confirm">
                {{ $t("118") }}
              </span>
            </div>
          </div>
        </div>
        <div class="buttonWrap">
          <button class="point large" @click="submit">{{ $t("120") }}</button>
          <button
            class="cancel large"
            @click="$router.push(`/${accountId}/login`)"
          >
            {{ $t("btn-cancel") }}
          </button>
        </div>
        <div class="error" v-if="error">
          {{ $t("117") }}
        </div>
        <p class="subText">
          {{ $t("121") }}
        </p>
      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import Footer from "@/components/Footer";
import { changePwd, checkSessionKey } from "@/api/login";

export default {
  components: { Footer },
  mixins: [format],
  data() {
    return {
      password: "",
      passwordConfirm: "",
      confirm: false,
      sessionKey: "",
      accountId: "",
      error: false,
    };
  },
  mounted() {
    this.accountId = this.$route.params.accountId;
    this.sessionKey = this.$route.query.sessionKey;
    this.checkSessionKey()
  },
  methods: {
    checkSessionKey(){
      checkSessionKey(this.accountId, this.sessionKey).then((res) => {
        if (res.data.result != 0) {
          alert(this.$t("alert-pw-change-check-key-fail"));
          this.$router.push(`/${this.accountId}/login`);
        }
      });
    },
    submit() {
      if (!this.checkPasswordFormat(this.password)) {
        this.error = true;
        return false;
      } else if (this.password != this.passwordConfirm) {
        this.confirm = true;
        return false;
      }
      let data = {
        newPassword: this.password,
      };
      changePwd(this.accountId, this.sessionKey, data).then((res) => {
        if (res.data.result == 0) {
          alert(this.$t("alert-pw-change-complete"));
          this.$router.push(`/${this.accountId}/login`);
        } else {
          alert(this.$t("alert-pw-change-fail"));
        }
      });
    },
  },
};
</script>
